import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';

const initialState = {
  existingChatList: [],
  globalSearchChatList: [],
  chatListLoading: false,
  globalSearchChatListLoading: false,
  chatListError: null,
  globalSearchChatListError: null,
  newUserList: [],
  messagesArray: [],
  screenLayout: 'split',
  targetMessageId: null,
  messageInputValue: '',
  isAddUserModalOpen: false,
  usersForChatLoading: false,
  userPosition: null,
  currentUser: null,
  chatSocketId: null,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getAllExistingChatList(state, action) {
      state.existingChatList = action.payload.data;
    },
    updateAllExistingChatList(state, action) {
      state.existingChatList = action.payload;
    },
    updateGlobalSearchChatList(state, action) {
      state.globalSearchChatList = action.payload;
    },
    getAllNewUserList(state, action) {
      state.newUserList = action.payload.data;
    },
    updateMessagesArray(state, action) {
      state.messagesArray = action.payload;
    },
    updateScreenLayout(state, action) {
      state.screenLayout = action.payload;
    },
    updateTargetMessageId(state, action) {
      state.targetMessageId = action.payload;
    },
    updateMessageInputValue(state, action) {
      state.messageInputValue = action.payload;
    },
    updateIsAddUserModalOpen(state, action) {
      state.isAddUserModalOpen = action.payload;
    },
    updateUserPosition(state, action) {
      state.userPosition = action.payload;
    },
    updateCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    updateChatSocketId(state, action) {
      state.chatSocketId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('chat/getAllExistingChatList/pending', (state, action) => {
      state.chatListLoading = true;
    });
    builder.addCase(
      'chat/getAllExistingChatList/fulfilled',
      (state, action) => {
        state.chatListLoading = false;
        state.existingChatList = action.payload.data;
      }
    );
    builder.addCase('chat/getAllExistingChatList/rejected', (state, action) => {
      state.chatListLoading = false;
      state.chatListError = action.payload;
    });
    builder.addCase(
      'chat/fetchGlobalSearchChatList/pending',
      (state, action) => {
        state.globalSearchChatListLoading = true;
        state.globalSearchChatListError = null;
      }
    );
    builder.addCase(
      'chat/fetchGlobalSearchChatList/fulfilled',
      (state, action) => {
        state.globalSearchChatListLoading = false;
        state.globalSearchChatListError = null;
        state.globalSearchChatList = action.payload;
      }
    );
    builder.addCase(
      'chat/fetchGlobalSearchChatList/rejected',
      (state, action) => {
        state.globalSearchChatListLoading = false;
        state.globalSearchChatListError = action.payload;
      }
    );
    builder.addCase('chat/fetchAllUsersForChat/pending', (state, action) => {
      state.usersForChatLoading = true;
    });
    builder.addCase('chat/fetchAllUsersForChat/fulfilled', (state, action) => {
      state.usersForChatLoading = false;
      state.newUserList = action.payload;
    });
    builder.addCase('chat/fetchAllUsersForChat/rejected', (state, action) => {
      state.usersForChatLoading = false;
      state.chatListError = action.payload;
    });
  },
});

export default slice.reducer;

export const {
  updateAllExistingChatList,
  getAllNewUserList,
  updateGlobalSearchChatList,
  updateMessagesArray,
  updateScreenLayout,
  updateTargetMessageId,
  updateMessageInputValue,
  updateIsAddUserModalOpen,
  updateUserPosition,
  updateCurrentUser,
  updateChatSocketId,
} = slice.actions;

export const getAllExistingChatList = createAsyncThunk(
  'chat/getAllExistingChatList',
  async (params, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(`admins/get-chats`, {
        ...(params && { params }),
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const groupDataByRoomId = (data) => {
  const grouped = data.reduce((acc, item) => {
    if (!acc[item.roomId]) {
      acc[item.roomId] = {
        roomId: item.roomId,
        user: {
          id: item.userId,
          name: item.userName,
          avatar: item.avatar,
          email: item.email,
          phoneNumber: item.phoneNumber,
        },
        data: [],
      };
    }
    acc[item.roomId].data.push(item);
    return acc;
  }, {});

  return Object.values(grouped);
};

export const fetchGlobalSearchChatList = createAsyncThunk(
  'chat/fetchGlobalSearchChatList',
  async (params, thunkAPI) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`admins/get-chats`, {
        ...(params && { params }),
      });
      const groupedData = groupDataByRoomId(data);
      return groupedData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export function updateAllExistingChatAction(data) {
  return async () => {
    try {
      dispatch(slice.actions.updateAllExistingChatList(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export const fetchAllUsersForChat = createAsyncThunk(
  'chat/fetchAllUsersForChat',
  async (params, thunkAPI) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`admins/users-for-chat`);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
